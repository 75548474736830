type ScriptDefinition = {
  src: string;
  onLoad?: () => void;
  browser?: 'all' | 'modern' | 'legacy';
};

type JsBundle = {
  legacy: string;
  modern: string;
};

export function createScriptElement({
  src,
  onLoad,
  browser = 'all',
}: ScriptDefinition): HTMLScriptElement {
  const scriptElement = document.createElement('script');

  if (browser === 'legacy') {
    scriptElement.noModule = true;
  } else if (browser === 'modern') {
    scriptElement.setAttribute('type', 'module');
  } else {
    scriptElement.setAttribute('type', 'text/javascript');
  }

  scriptElement.setAttribute('src', src);
  scriptElement.setAttribute('defer', 'defer');

  if (onLoad) {
    scriptElement.onload = function () {
      onLoad();
    };
  }

  return scriptElement;
}

export function addJsScript(src: string, onLoad?: () => void): void {
  document.head.appendChild(createScriptElement({ src, onLoad }));
}

export function addJsScriptBundle(src: JsBundle, onLoad?: () => void): void {
  document.head.appendChild(
    createScriptElement({ src: src.modern, onLoad, browser: 'modern' }),
  );

  document.head.appendChild(
    createScriptElement({ src: src.legacy, onLoad, browser: 'legacy' }),
  );
}

export function addCss(cssPath: string): void {
  const linkElement = document.createElement('link');

  linkElement.setAttribute('rel', 'stylesheet');
  linkElement.setAttribute('href', cssPath);

  document.head.appendChild(linkElement);
}
