import { addJsScript } from '../../core/js/add-content-functions.js';
import { widgetLazyLoader } from '../../core/js/lazy-loading.js';

const initFooterWidgets = (): void => {
  let frontboxesJsScriptLoaded = false;

  ['.mm-box1-container', '.mm-box2-container', '.mm-box3-container'].forEach(
    function (box) {
      widgetLazyLoader(box, function () {
        if (!frontboxesJsScriptLoaded) {
          frontboxesJsScriptLoaded = true;

          addJsScript(
            'https://mm.aftenposten.no/tools/front-widgets/widgets/dist/app.js',
          );
        }
      });
    },
  );
};

initFooterWidgets();
