// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import lozad from 'lozad';

const fallbackWindowHeight = 1000;
const numberOfScreensToPreload = 1;
const windowHeight =
  document.documentElement.clientHeight || fallbackWindowHeight;
const loadingThreshold = windowHeight * numberOfScreensToPreload;

const lazyWidgetObserverProperties = {
  rootMargin: loadingThreshold + 'px 0px',
};

let imagesObserver: { observe: () => void };

export function observeImages(): void {
  if (!imagesObserver) {
    imagesObserver = lozad('.b-lazy, img[data-src]', {
      rootMargin: loadingThreshold + 'px 0px',
      loaded: function (loadedElement: Element) {
        loadedElement.classList.add('b-loaded');
      },
    });
  }

  imagesObserver.observe();
}

export function observeImagesOnLoad(): void {
  window.addEventListener('DOMContentLoaded', function () {
    observeImages();
  });
}

export function widgetLazyLoader(
  placeholderSelector: string,
  loadCallback: (e: Element) => void,
): void {
  const widgetObserver = new IntersectionObserver(function (entries, observer) {
    entries.forEach(function (entry) {
      if (entry.intersectionRatio > 0 || entry.isIntersecting) {
        observer.unobserve(entry.target);
        loadCallback(entry.target);
      }
    });
  }, lazyWidgetObserverProperties);

  const placeholders = document.querySelectorAll(placeholderSelector);

  [].forEach.call(placeholders, function (placeholder) {
    widgetObserver.observe(placeholder);
  });
}
